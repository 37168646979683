import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  SimpleGrid,
  GridItem,
  Heading,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  FormHelperText,
  Textarea,
  Icon,
  Button,
  VisuallyHidden,
  FormErrorMessage
} from "@chakra-ui/react";
import AlertComponent from "./Alerts";

const FormComponent = ({ onClose }) => {
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting }
      } = useForm();
      const [success, setSuccess] = useState(false)

    const onSubmit = async (values) => {
        try {
            const resp = await fetch(
                // 'http://127.0.0.1:8787/posts'
                "https://posts-api.lilit.workers.dev/posts"  , {
                    method: 'POST',
                    mode: 'no-cors', // no-cors, *cors, same-origin
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                }
            );
            await resp.text()
            onClose()
            setSuccess(current => !current)
        } catch (err) {
            console.log(err)
        }
      }

      console.log(errors)

  return (
    <Box bg={useColorModeValue("gray.50", "inherit")} p={10}>
        { success && 
            <AlertComponent title={"Success"} status={"success"} msg={"Your thoughts have been shared with the world"} />
        }
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid
            display={{ base: "initial", md: "grid" }}
            columns={{ md: 3 }}
            spacing={{ md: 6 }}
            >
            <GridItem colSpan={{ md: 1 }}>
                <Box px={[4, 0]}>
                <Heading fontSize="lg" fontWeight="md" lineHeight="6">
                    Share Your Thoughts
                </Heading>
                <Text
                    mt={1}
                    fontSize="sm"
                    color={useColorModeValue("gray.600", "gray.400")}
                >
                    Let the world know what you're thinking. Express your thougts...
                </Text>
                </Box>
            </GridItem>
            <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
              
                <Stack
                    px={4}
                    py={5}
                    bg={useColorModeValue("white", "gray.700")}
                    spacing={6}
                    p={{ sm: 6 }}
                >
                    <SimpleGrid columns={3} spacing={6}>
                        <FormControl as={GridItem} colSpan={[3, 2]} isInvalid={errors.name}>
                            <FormLabel
                            fontSize="sm"
                            fontWeight="md"
                            htmlFor="username"
                            color={useColorModeValue("gray.700", "gray.50")}
                            >
                            Title
                            </FormLabel>
                            <InputGroup size="sm">
                            <Input
                                id="username"
                                type="text"
                                placeholder="Lilith"
                                focusBorderColor={errors.name ? "red" : "brand.400"}
                                rounded="md"
                                {...register("username", {
                                    required: "Username is required",
                                    minLength: { value: 4, message: "Minimum length should be 4" }
                                })}
                            />
                            </InputGroup>
                            { errors.name && <AlertComponent status={"error"} title={"Error"} message={errors.name.message}/> }
                            {/* <FormErrorMessage>
                                {errors.name && errors.name.message}
                            </FormErrorMessage> */}
                        </FormControl>
                    </SimpleGrid>

                    <SimpleGrid columns={3} spacing={6}>
                        <FormControl as={GridItem} colSpan={[3, 2]} isInvalid={errors.name}>
                            <FormLabel
                            fontSize="sm"
                            fontWeight="md"
                            htmlFor="title"
                            color={useColorModeValue("gray.700", "gray.50")}
                            >
                            Title
                            </FormLabel>
                            <InputGroup size="sm">
                            <Input
                                id="title"
                                type="text"
                                placeholder="I need this internship"
                                focusBorderColor="brand.400"
                                rounded="md"
                                {...register("title", {
                                    required: "This is required",
                                    minLength: { value: 4, message: "Minimum length should be 4" }
                                })}
                            />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.name && errors.name.message}
                            </FormErrorMessage>
                        </FormControl>
                    </SimpleGrid>

                    <div>
                    <FormControl mt={1} isInvalid={errors.name}>
                        <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        htmlFor="content"
                        color={useColorModeValue("gray.700", "gray.50")}
                        >
                        Content
                        </FormLabel>
                        <Textarea
                        placeholder="you@example.com"
                        mt={1}
                        rows={3}
                        shadow="sm"
                        id="content"
                        focusBorderColor="brand.400"
                        fontSize={{ sm: "sm" }}
                        {...register("content", {
                            required: "Add thoughts",
                        })}
                        />
                        <FormHelperText>
                        Expatiate Your Thoughts
                        </FormHelperText>
                        <FormErrorMessage>
                            {errors.name && errors.name.message}
                        </FormErrorMessage>
                    </FormControl>
                    </div>

                    <FormControl>
                    <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        color={useColorModeValue("gray.700", "gray.50")}
                    >
                        Add an Image
                    </FormLabel>
                    <Flex
                        mt={1}
                        justify="center"
                        px={6}
                        pt={5}
                        pb={6}
                        borderWidth={2}
                        borderColor={useColorModeValue("gray.300", "gray.500")}
                        borderStyle="dashed"
                        rounded="md"
                    >
                        <Stack spacing={1} textAlign="center">
                        <Icon
                            mx="auto"
                            boxSize={12}
                            color={useColorModeValue("gray.400", "gray.500")}
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />
                        </Icon>
                        <Flex
                            fontSize="sm"
                            color={useColorModeValue("gray.600", "gray.400")}
                            alignItems="baseline"
                        >
                            <chakra.label
                            htmlFor="file-upload"
                            cursor="pointer"
                            rounded="md"
                            fontSize="md"
                            color={useColorModeValue("brand.600", "brand.200")}
                            pos="relative"
                            _hover={{
                                color: useColorModeValue("brand.400", "brand.300"),
                            }}
                            >
                            <span>Upload a file</span>
                            <VisuallyHidden>
                                <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                />
                            </VisuallyHidden>
                            </chakra.label>
                            <Text pl={1}>or drag and drop</Text>
                        </Flex>
                        <Text
                            fontSize="xs"
                            color={useColorModeValue("gray.500", "gray.50")}
                        >
                            PNG, JPG, GIF up to 10MB
                        </Text>
                        </Stack>
                    </Flex>
                    </FormControl>
                </Stack>
                <Box
                    px={{ base: 4, sm: 6 }}
                    py={3}
                    bg={useColorModeValue("gray.50", "gray.900")}
                    textAlign="right"
                >
                    <Button
                    type="submit"
                    colorScheme="brand"
                    _focus={{ shadow: "" }}
                    fontWeight="md"
                    isLoading={isSubmitting}
                    >
                    Post
                    </Button>
                </Box>
            </GridItem>
            </SimpleGrid>
        </form>
      </Box>
    </Box>
  );
}

export default FormComponent