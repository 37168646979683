import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton
  } from "@chakra-ui/react"

const AlertComponent = ({ status, msg, title }) => {
    return (
        <Alert status={status}>
            <AlertIcon />
            <AlertTitle mr={2}>{title}</AlertTitle>
            <AlertDescription>{msg}</AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" />
        </Alert>    
    )
}

export default AlertComponent