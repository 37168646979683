import React, { useEffect, useState } from "react";
import {
    Flex,
    useColorModeValue,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
  } from "@chakra-ui/react";
import Post from "./postList";
import Navbar from './Navbar'
import FormComponent from "./Form";


const Posts = () => {
  const [posts, setPosts] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [scrollBehavior, ] = React.useState("inside")


  useEffect(() => {
      try {
        const getPosts = async () => {
            const resp = await fetch(
              "https://posts-api.lilit.workers.dev/posts"
            );
            const postsResp = await resp.json();
            setPosts(postsResp);
          };
        
          getPosts();
      } catch (err) {
          console.log(err)
      }

  }, []);

  return (
    <>
        <Navbar onOpen={onOpen}/>
        <Flex
        bg={useColorModeValue("#F9FAFB", "gray.600")}
        p={50}
        w="full"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        >
            {posts.map((post) => (
                <Post id={post.id} 
                    date={post.created_At}  
                    category={"Design"} 
                    username={post.username}
                    title={post.title}
                    content={post.content}
                />
            ))}
        </Flex>
        <Modal onClose={onClose} isOpen={isOpen} size={"xl"} scrollBehavior={scrollBehavior} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
                <FormComponent onClose={onClose}/>
            </ModalBody>
          </ModalContent>
        </Modal>
    </>
  );
};


export default Posts;